import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../auth';
import {
  ApproversList,
  DestroyItemsCount,
  DisposalRequest,
  DisposalRequestCount,
  DisposalRequestList,
  DisposalRequestStatus,
} from '../types';

import { config } from '../config';

type RequestCountResponse = {
  $count: number;
  ['GroupBy_ApproverResponses.ApproverIdToStatus']: string;
  ['GroupBy_Status']: string;
};

const ENDPOINT = 'DisposalRequest';

export const requestApi = createApi({
  reducerPath: 'requestApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders,
  }),
  tagTypes: ['DisposalRequest'],
  endpoints: (builder) => ({
    /** This function doesn't seem to be used */
    getRequestsByApprovalStatus: builder.query<
      DisposalRequestList,
      { userId: string; status: string }
    >({
      query: ({ userId, status }) => {
        return {
          /* When this is used, please limit the properties requested in $select=* */
          url: `${ENDPOINT}?$select=*&$expand=RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo)&$filter=ApproverResponses/ApproverIdToStatus eq '${userId}_${status}'`,
          method: 'GET',
        };
      },
    }),
    /** This function doesn't seem to be used */
    getRequestsByStatus: builder.query<DisposalRequestList, { status: string }>(
      {
        query: ({ status }) => {
          return {
            /* When this is used, please limit the properties requested in $select=* */
            url: `${ENDPOINT}?$select=*&$expand=RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo)&$filter=status eq '${status}'`,
            method: 'GET',
          };
        },
      }
    ),
    getRequestCountsByApprovalStatus: builder.query<
      DisposalRequestCount[],
      { userId: string }
    >({
      query: ({ userId }) => {
        return {
          url: `${ENDPOINT}?$expand=ApproverResponses&$filter=ApproverResponses/ApproverId eq ${userId}&$apply=groupby((ApproverResponses/ApproverIdToStatus), aggregate($count as 'DRs'))`,
          method: 'GET',
        };
      },
      transformResponse: (
        {
          value: requestCountList,
        }: {
          value: RequestCountResponse[];
        },
        meta,
        args
      ) => {
        const counts: DisposalRequestCount[] = [];
        requestCountList?.forEach((count) => {
          const [_userId, _status] = count[
            'GroupBy_ApproverResponses.ApproverIdToStatus'
          ]?.split('_') ?? ['unkown', 'unknown'];
          if (_userId === args.userId) {
            counts.push({
              Count: count.$count,
              UserId: _userId,
              Status: _status,
            } as DisposalRequestCount);
          }
        });
        return counts;
      },
      providesTags: ['DisposalRequest'],
    }),
    getRequestCountsByStatus: builder.query<DisposalRequestCount[], {}>({
      query: () => {
        return {
          url: `${ENDPOINT}?$apply=groupby((Status), aggregate($count as 'DRs'))`,
          method: 'GET',
        };
      },
      transformResponse: (
        {
          value: requestCountList,
        }: {
          value: RequestCountResponse[];
        },
        meta,
        args
      ) => {
        const counts: DisposalRequestCount[] = [];
        requestCountList?.forEach((count) => {
          counts.push({
            Count: count.$count,
            Status: count['GroupBy_Status'],
          } as DisposalRequestCount);
        });
        return counts;
      },
      providesTags: ['DisposalRequest'],
    }),
    getRequestDetails: builder.query<DisposalRequest, { requestId: string }>({
      query: ({ requestId }) => {
        const selectQuery: string = 'Name,DisposalAction,Status,DateRequested,DateCreatedInEnc,RequestComments,EncompaasUrl';
        return {
          url: `${ENDPOINT}('${requestId}')?$select=${selectQuery}&$expand=RequestedBy,ApproverResponses($select=ApproverId,Status,DateResponded,ResponseComments,Order,DelegatedToId,ApproverIdToStatus;$expand=Approver,DelegatedTo),RetentionClass($select=ID,DisplayName,FullPath)`,
          method: 'GET',
        };
      },
      providesTags: ['DisposalRequest'],
    }),
    getRequestItems: builder.query<any, { requestId: string }>({
      query: ({ requestId }) => {
        const BASE_URL: string = `${config.API_BASE_URL}/Enc.Item?`;
        const selectQuery: string = 'ID';
        return {
          url: `${BASE_URL}$filter=DisposalRequests/ID eq ${requestId}&$select=${selectQuery}&$expand=DisposalStates($select=DisposalStatus,RetentionClassId)&$inlinecount=allpages`,
          method: 'GET',
        };
      },
    }),
    getApproversList: builder.query<ApproversList, void>({
      // TODO: set filter=IsActive eq true to allow users with access only
      query: () => {
        return {
          url: `User?$top=50&$count=true&$select=ID,_Display,Department&$orderby=department`,
          method: 'GET',
        };
      },
    }),
    getApproversListWithParams: builder.query<
      ApproversList,
      { searchFor?: string }
    >({
      query: ({ searchFor }) => {
        if (!!searchFor) {
          return {
            url: `User?$top=50&$count=true&$select=ID,_Display,Department&$filter=contains(_Display, '${searchFor}') or contains(Department, '${searchFor}')&$orderby=department`,
            method: 'GET',
          };
        }
        return {
          url: `User?$top=50&$count=true&$select=ID,_Display,Department&$orderby=department`,
          method: 'GET',
        };
      },
    }),
    getDestructionInProgressItemsCount: builder.query<DestroyItemsCount, {}>({
      query: () => {
        return {
          url: `Item?$count=true&$filter=DisposalStates/Any(dr:dr/DisposalStatus eq 'DestructionActioned') AND DisposalDetails/Any(ds:ds/FailureDetail in ('None','Src_DeletionSuccessful') AND ds/CompletedDate eq null)`,
          method: 'GET',
        }
      },
      transformResponse: (response) => {
        return { count: response['@odata.count'] };
      },
      providesTags: ['DisposalRequest'],
    }),
    getFailedToDestroyItemsCount: builder.query<DestroyItemsCount, {}>({
      query: () => {
        return {
          url: `Item?$count=true&$filter=(DisposalDetails/Any(dd:startswith(dd/FailureDetail,'Src_') or startswith(dd/FailureDetail,'Enc_')))`,
          method: 'GET',
        }
      },
      transformResponse: (response) => {
        return { count: response['@odata.count'] };
      },
      providesTags: ['DisposalRequest'],
    }),
    getDestroyedItemsCount: builder.query<DestroyItemsCount, {}>({
      query: () => {
        return {
          url: `Item?$count=true&$filter=Status eq 'Destroyed'`,
          method: 'GET',
        }
      },
      transformResponse: (response) => {
        return { count: response['@odata.count'] };
      },
      providesTags: ['DisposalRequest'],
    }),

    
    approveDisposalRequest: builder.mutation<
      DisposalRequest,
      { comment: string; requestId: string | undefined }
    >({
      query: ({ comment, requestId }) => ({
        url: `/${ENDPOINT}('${requestId}')/Approve?`,
        method: 'POST',
        body: { comments: comment },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['DisposalRequest'],
    }),
    rejectDisposalRequest: builder.mutation<
      DisposalRequest,
      { comment: string; requestId: string | undefined }
    >({
      query: ({ comment, requestId }) => ({
        url: `/${ENDPOINT}('${requestId}')/Reject?`,
        method: 'POST',
        body: { comments: comment },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['DisposalRequest'],
    }),
    delegateDisposalRequest: builder.mutation<
      DisposalRequest,
      {
        comment: string;
        delegateToUserId: string;
        requestId: string | undefined;
      }
    >({
      query: ({ comment, delegateToUserId, requestId }) => ({
        url: `/${ENDPOINT}('${requestId}')/Delegate`,
        method: 'POST',
        body: { comments: comment, delegatedToUserId: delegateToUserId },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['DisposalRequest'],
    }),
    updateDisposalRequest: builder.mutation<
      DisposalRequest,
      {
        name: string;
        comment: string;
        requestId?: string;
        // TODO: create type for api approversList when update the query
        approversList?: {
          Approver: { ID: string };
          Status: string;
          Order: number;
        }[];
        requestedBy?: string;
      }
    >({
      query: ({ name, comment, requestId, approversList, requestedBy }) => ({
        url: `/DisposalRequest('${requestId}')`,
        method: 'PATCH',
        body: {
          Status: DisposalRequestStatus.InApproval,
          RequestedBy: { ID: requestedBy },
          Name: name,
          RequestComments: comment,
          ApproverResponses: {
            value: approversList,
          },
        },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['DisposalRequest'],
    }),

    updateDisposalRequestApprovers: builder.mutation<
      DisposalRequest,
      {
        requestId?: string;
        approversList?: {
          Approver: { ID: string };
          Status: string;
          Order: number;
        }[];
        requestedById?: string;
      }
    >({
      query: ({ requestId, approversList, requestedById }) => ({
        url: `/DisposalRequest('${requestId}')`,
        method: 'PATCH',
        body: {
          ApproverResponses: {
            value: approversList,
          },
          ...(requestedById && { RequestedBy: { ID: requestedById } }),
        },
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['DisposalRequest'],
    }),
    updateDisposalRequestProps: builder.mutation<
      DisposalRequest,
      {
        requestId?: string | number;
        body?: Object;
      }
    >({
      query: ({ requestId, body }) => ({
        url: `/DisposalRequest('${requestId}')`,
        method: 'PATCH',
        body: body,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      invalidatesTags: ['DisposalRequest'],
    }),
    startDisposalJob: builder.mutation<any, { requestId: string | number }>({
      query: ({ requestId }) => {
        const BASE_URL: string = `${config.API_BASE_URL}`;
        return {
          url: `${BASE_URL}/DisposalRequest('${requestId}')/StartDisposalJob`,
          method: 'GET',
        };
      },
    }),
    closeDisposal: builder.mutation<any, { requestId: string | number }>({
      query: ({ requestId }) => {
        const BASE_URL: string = `${config.API_BASE_URL}`;
        return {
          url: `${BASE_URL}/DisposalRequest('${requestId}')/Close`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetRequestsByApprovalStatusQuery,
  useGetRequestsByStatusQuery,
  useGetRequestCountsByApprovalStatusQuery,
  useGetRequestCountsByStatusQuery,
  useGetRequestDetailsQuery,
  useApproveDisposalRequestMutation,
  useRejectDisposalRequestMutation,
  useDelegateDisposalRequestMutation,
  useGetApproversListQuery,
  useUpdateDisposalRequestMutation,
  useUpdateDisposalRequestApproversMutation,
  useUpdateDisposalRequestPropsMutation,
  useGetRequestItemsQuery,
  useStartDisposalJobMutation,
  useCloseDisposalMutation,
  useLazyGetApproversListWithParamsQuery,
  useGetDestructionInProgressItemsCountQuery,
  useGetDestroyedItemsCountQuery,
  useGetFailedToDestroyItemsCountQuery,
} = requestApi;
