import {
  ErrorCircle16Regular,
  PeopleCheckmark20Regular,
} from '@fluentui/react-icons';
import { Divider, Link, TextField, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useApprovers } from '../../hooks';
import {
  Approver,
  ApproversList,
  DisposalRequest,
} from '../../types/requestTypes';
import { Box } from '../Box';
import { CopyToClipboardButton } from '../CopyToClipboardButton';
import { DetailsRow } from '../DetailsRow';
import { EditApprovers } from '../EditApprovers';
import CustomActionModal from './CustomActionModal';
import { ActionButton } from './components/ActionButton';

export type DisposalApprovalDialogProps = {
  title?: string;
  disposalRequest?: DisposalRequest | undefined;
  approvers?: ApproversList | undefined;
  open: boolean;
  onClose?: (selectedApprovers?: Approver[]) => void;
  onAction?: (name: string, comment: string, approvers: Approver[]) => void;
  currentSelectedApprovers: Approver[];
};

export const DisposalApprovalDialog = ({
  title = 'Request Disposal Approval',
  disposalRequest,
  approvers,
  open,
  onClose,
  onAction,
  currentSelectedApprovers,
}: DisposalApprovalDialogProps) => {
  //states and hooks
  const [name, setName] = useState(disposalRequest?.Name);
  const [comment, setComment] = useState(disposalRequest?.RequestComments);

  useEffect(() => {
    disposalRequest?.ApproverResponses?.value.forEach((_approverResponse) => {
      if (
        !currentSelectedApprovers.some(
          (e) => e.ID === _approverResponse.Approver.ID
        )
      )
        currentSelectedApprovers.push({
          _Display: _approverResponse.Approver?.DisplayName!,
          ID: _approverResponse.Approver?.ID!,
          Department: '',
          Status: _approverResponse?.Status!,
        });
    });
  }, [approvers]);

  const {
    selectedApprovers,
    setSelectedApprovers,
    isApproversListValid,
    setIsApproversListValid,
  } = useApprovers(currentSelectedApprovers);

  const theme = useTheme();

  //effects
  useEffect(() => {
    setSelectedApprovers(currentSelectedApprovers);
  }, [currentSelectedApprovers]);

  useEffect(() => {
    setName(disposalRequest?.Name);
    setComment(disposalRequest?.RequestComments);
  }, [disposalRequest]);

  return (
    <CustomActionModal
      title={title}
      onClose={() => onClose(selectedApprovers)}
      open={open}
      icon={<PeopleCheckmark20Regular />}
      actionButton={
        <ActionButton
          onAction={onAction}
          name={name}
          comment={comment}
          selectedApprovers={selectedApprovers}
          setIsValidCondition={(approvers) => approvers.length > 0}
          setValidity={setIsApproversListValid}
          label='Request Approval'
        />
      }
    >
      <DetailsRow
        label='Name'
        data={
          <Box
            alignItems='start'
            background='none'
            style={{
              overflow: 'hidden',
              width: '100%'
            }}
          >
            <TextField
              sx={{ height: '3rem', width: '100%' }}
              placeholder='Enter name'
              onChange={(event) => setName(event.target.value)}
              value={name}
            />
          </Box>
        }
        size='small'
      />
      <DetailsRow
        label='Disposal Action'
        data={disposalRequest?.DisposalAction}
        size='small'
      />
      <DetailsRow
        label='Web Link'
        data={
          <Box
            background='none'
            direction='row'
            alignItems='center'
            style={{
              marginTop: '-0.2rem',
            }}
          >
            <Link
              variant='body2'
              sx={{
                width: '90%',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              href={disposalRequest.EncompaasUrl}
            >
              {disposalRequest.EncompaasUrl}
            </Link>
            <CopyToClipboardButton
              text={disposalRequest.EncompaasUrl}
              color={theme.palette.primary.main}
            />
          </Box>
        }
        size='small'
        alignData
      />
      <Divider
        sx={{
          margin: '1.5rem 0',
        }}
      />

      <EditApprovers
        approvers={approvers}
        setSelectedApprovers={setSelectedApprovers}
        selectedApprovers={selectedApprovers}
        isApproversListValid={isApproversListValid}
        setIsApproversListValid={setIsApproversListValid}
      />

      {!isApproversListValid && (
        <Box
          background='none'
          color='red'
          direction='row'
          style={{
            marginTop: '0.75rem',
          }}
        >
          <ErrorCircle16Regular
            color={theme.palette.warning.main}
            style={{
              marginRight: '.3rem',
            }}
          />
          <Typography variant='body3' color={theme.palette.warning.main}>
            Please specify approvers
          </Typography>
        </Box>
      )}

      <Typography
        variant='body1'
        mt={4}
        sx={{
          color: theme.palette.info.dark,
        }}
      >
        Comment
      </Typography>
      <TextField
        sx={{
          marginTop: '0.5rem',
        }}
        fullWidth
        maxRows={5}
        minRows={5}
        multiline
        onChange={(event) => setComment(event.target.value)}
        value={comment}
      />
    </CustomActionModal>
  );
};
