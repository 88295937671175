import { memo, useEffect, useRef } from 'react';
import { Box } from '../../../Box';

import {
  TreeGridProperties,
  BusinessTypePropDefsValue,
  ItemBusinessObject,
} from '../../../../types';

import { useCustomTypeActions } from '../../../../hooks/useCustomTypeActions';
import { MemoizedCustomTypeTreeGrid } from '../../../CustomTypeTreeGrid';
import { OnChangeValue } from '../../EditCustomTypeDialog';

export type CustomTypeFieldProps = {
  onChange: (value: OnChangeValue) => void;
  propertyValue?: string;
  businessObject: ItemBusinessObject;
  treeGridProperties: TreeGridProperties;
  propertyDetails: BusinessTypePropDefsValue;
};

export const CustomTypeField = memo(
  ({
    onChange,
    propertyValue,
    businessObject,
    propertyDetails,
    treeGridProperties,
  }: CustomTypeFieldProps) => {
    const { treeGridData, handleAdd, handleDelete, handleActionComplete } =
      useCustomTypeActions({
        onChange,
        propertyValue,
        businessObject,
        propertyDetails,
        treeGridProperties,
      });

    const treeGridRef = useRef(null);

    useEffect(() => {
      treeGridRef.current.dataSource = treeGridData;
      treeGridRef.current.refresh();

      // Save the tree grid data in the local storage.
      // There's an issue on the structure of the tree grid data after editing
      // Just a workaround to fix that but please free to update the code.
      localStorage.setItem(
        'customTypeTreeGridData',
        JSON.stringify(treeGridData)
      );

      return () => localStorage.removeItem('customTypeTreeGridData');
    }, [treeGridData]);

    return (
      <Box
        padding='large'
        background='none'
        height='100%'
        style={{
          padding: 0,
        }}
      >
        <MemoizedCustomTypeTreeGrid
          ref={treeGridRef}
          treeGridData={treeGridData}
          propertyDetails={propertyDetails}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          handleActionComplete={handleActionComplete}
        />
      </Box>
    );
  }
);
