import { ApiUser } from './userTypes';
import { RetentionClass } from './itemTypes';

export type DisposalRequest = {
  ID: string | number;
  IsRead?: boolean;
  Name: string;
  Status: DisposalRequestStatus;
  DateCreatedInEnc?: string | null;
  RequestComments?: string;
  ItemCount?: number;
  RequestedById?: string | number;
  RequestedBy?: ApiUser;
  DateRequested?: string | null;
  RetentionClassId?: string | null;
  RetentionClass?: RetentionClass;
  DisposalAction?: string | null;
  ApproverResponses?: {
    value: ApproverResponse[];
  };
  //extra properties to store data
  Response?: ApproverResponse;
  DelegatedTo?: ApproverResponse;
  Exception?: string;
  EncompaasUrl?: string;
  ActionedById?: string;
  DateCompleted?: string | null;
};

export interface DisposalDetails {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  RetentionConflict: boolean;
  RejectedInOtherRequest: boolean;
  RelatedItemRetentionConflict: boolean;
  RelatedItemRejectedInOtherRequest: boolean;
  //extra properties to store exceptions
  FailedToDestroy?: boolean;
}

export interface DisposalDetailsCollection {
  $type: string;
  '@odata.context': string;
  value: DisposalDetails[];
}

export interface DisposalDetails {
  $type: string;
  ID: string;
  _Display: string;
  TypeDefId: string;
  RetentionConflict: boolean;
  RejectedInOtherRequest: boolean;
  RelatedItemRetentionConflict: boolean;
  RelatedItemRejectedInOtherRequest: boolean;
}

export interface DisposalDetailsCountCollection {
  '@odata.count': number;
  $type: string;
  '@odata.context': string;
  '@odata.nextlink': string;
  value: DisposalDetailsCount[];
}

export interface DisposalDetailsCount {
  $type: string
  ID: string
  _Display: string
  TypeDefId: string
  ItemId: string
  RetentionClassId: string
  DisposalRequestId: string
  AdditionalInformation: any
  IsDeferred: boolean
  FailureDetail: string
  CompletedDate: any
  RetentionConflict: boolean
  RelatedItemRetentionConflict: boolean
  RejectedInOtherRequest: boolean
  RelatedItemRejectedInOtherRequest: boolean
  DisposalRequestId_RetentionConflict: string
  DisposalRequestId_RelatedItemRetentionConflict: string
  DisposalRequestId_RejectedInOtherRequest: string
  DisposalRequestId_RelatedItemRejectedInOtherRequest: string
  EncompaasUrl: string
  _ImageId: any
  ETag: number
}

export type ApproverResponse = {
  ID: string | number;
  ApproverId: string | number;
  Status: DisposalRequestResponseStatus;
  Approver?: ApiUser;
  DateResponded: string;
  ResponseComments?: string | null;
  Order?: number;
  DelegatedToId?: string | null;
  ApproverIdToStatus?: string | null;
  DelegatedTo?: ApproverResponse | null;
};

export type DisposalRequestCount = {
  Count: number;
  UserId: string;
  Status: string;
};

export type DestroyItemsCount = {
  count: number;
};

export type DisposalRequestList = {
  value: DisposalRequest[];
  $type: string;
};

export enum DisposalRequestAction {
  None = 'None',
  Destroy = 'Destroy',
  Transfer = 'Transfer',
  Retain = 'Retain',
}

export const DisposalRequestActionCaption = {
  [DisposalRequestAction.None]: 'None',
  [DisposalRequestAction.Destroy]: 'Destroy',
  [DisposalRequestAction.Transfer]: 'Transfer',
  [DisposalRequestAction.Retain]: 'Retain',
};

//can support conjugation of the actions for future use
export const DisposalRequestActionDoingCaption = {
  [DisposalRequestAction.None]: 'None',
  [DisposalRequestAction.Destroy]: 'Destruction in Progress',
  [DisposalRequestAction.Transfer]: 'Transfer in Progress',
  [DisposalRequestAction.Retain]: 'Retention in Progress',
};
export const DisposalRequestActionDoneCaption = {
  [DisposalRequestAction.None]: 'None',
  [DisposalRequestAction.Destroy]: 'Destroyed',
  [DisposalRequestAction.Transfer]: 'Transferred',
  [DisposalRequestAction.Retain]: 'Retained',
};

//these values will change
export enum DisposalRequestStatus {
  New = 'New', // new
  InApproval = 'ApprovalPending', // in approval
  Approved = 'Approved', // approved
  Rejected = 'Rejected', // rejected
  InProgress = 'InProgress', // action in progress
  ItemDestroyInProgress = 'ItemDestroyInProgress', // action in progress
  Complete = 'Complete', // actioned
  Failed = 'Abandoned', // failed
  ItemDestroyComplete = 'ItemDestroyComplete', // Destroy item complete (TEMP)
  InProgressOrComplete = 'InProgressOrComplete' // compound status for filtering
}

export const DisposalRequestStatusCaption = {
  [DisposalRequestStatus.New]: 'New',
  [DisposalRequestStatus.InApproval]: 'In Approval',
  [DisposalRequestStatus.Approved]: 'Approved',
  [DisposalRequestStatus.Rejected]: 'Rejected',
  [DisposalRequestStatus.InProgress]: 'Destruction Actioned',
  [DisposalRequestStatus.ItemDestroyInProgress]:
    DisposalRequestActionDoingCaption[DisposalRequestAction.Destroy],
  [DisposalRequestStatus.Complete]: 'Destruction Complete',
  [DisposalRequestStatus.InProgressOrComplete]: 'Destruction Actioned',
  [DisposalRequestStatus.Failed]:
    'Failed to ' + DisposalRequestActionCaption[DisposalRequestAction.Destroy],
  [DisposalRequestStatus.ItemDestroyComplete]:
    DisposalRequestActionDoneCaption[DisposalRequestAction.Destroy],
};

//separate enum for responses
export enum DisposalRequestResponseStatus {
  Pending = 'PendingApproval',
  Waiting = 'Waiting',
  Approved = 'Approved',
  Delegated = 'Delegated',
  Rejected = 'Rejected',
}

export const DisposalRequestResponseStatusCaption = {
  [DisposalRequestResponseStatus.Pending]: 'Pending',
  [DisposalRequestResponseStatus.Waiting]: 'Waiting',
  [DisposalRequestResponseStatus.Approved]: 'Approved',
  [DisposalRequestResponseStatus.Delegated]: 'Delegated',
  [DisposalRequestResponseStatus.Rejected]: 'Rejected',
};

export type Approver = {
  _Display: string;
  ID: string;
  Department?: string;
  Status?: DisposalRequestResponseStatus;
  Order?: number;
};

export type ApproversList = {
  value: Approver[];
};
