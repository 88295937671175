import { Highlight20Regular } from '@fluentui/react-icons';
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  Inject,
  Resize,
  Sort,
  Filter,
  TreeGridComponent,
} from '@syncfusion/ej2-react-treegrid';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { palette } from '../../themes';
import { Chip } from '../chip';
import { getParentNameFromObject } from '../item/ItemPropertiesUtil';
import { PropertyValueTemplate } from '../item/template/PropertyValueTemplate';
import { Typography } from '../Typography';

export const ItemPropertiesGrid = ({
  gridRef,
  menuItem,
  formDetails,
  editOptions,
  itemTypeName,
  editTemplate,
  handleRowSelection,
  handleActionComplete,
  handleRowSelecting,
  handleRecordDoubleClick,
}) => {
  const hoverAttributes = { class: 'editable-column' };

  return (
    <TreeGridComponent
      ref={gridRef}
      treeColumnIndex={0}
      allowSorting={true}
      width='100%'
      enablePersistence
      childMapping='Items'
      id='item-details-properties-grid'
      className='item-details-properties-grid'
      loadingIndicator={{ indicatorType: 'Shimmer' }}
      allowResizing={true}
      editSettings={editOptions}
      rowSelected={handleRowSelection}
      rowSelecting={handleRowSelecting}
      actionComplete={handleActionComplete}
      recordDoubleClick={handleRecordDoubleClick}
    >
      <ColumnsDirective>
        <ColumnDirective
          allowEditing={false}
          field='propertyName'
          headerText='PROPERTY NAME'
          width='18.5rem'
          template={(item) => {
            const propertyName = item?.propertyName;

            return (
              <Typography
                variant='body1'
                sx={{
                  color: palette.navy[900],
                  WebkitLineClamp: 3,
                }}
              >
                {propertyName}
              </Typography>
            );
          }}
        />
        <ColumnDirective
          allowEditing={true}
          field='value'
          headerText='VALUE'
          width='auto'
          edit={editTemplate}
          template={(item) => {
            const parentName = getParentNameFromObject(item);

            const _isAllowEditing =
              !item?.isCollection &&
              !item?.childRecords?.length &&
              (parentName in formDetails?.BusinessObject ||
                [
                  'Business Type',
                  'Perspective Classes',
                  'Retention Classes',
                ].includes(item?.propertyName));

            return (
              <PropertyValueTemplate
                item={item.value}
                isEditable={_isAllowEditing}
              />
            );
          }}
          customAttributes={hoverAttributes}
        />
        {menuItem !== itemTypeName && (
          <ColumnDirective
            allowEditing={false}
            field='highlights'
            headerText='HIGHLIGHTS'
            width='auto'
            template={(item) =>
              item.highlights ? (
                <Chip
                  border={false}
                  title={item.highlights}
                  icon={<Highlight20Regular />}
                  color='success'
                  sx={{
                    backgroundColor: palette.aqua[50],
                    color: palette.aqua[900],
                  }}
                />
              ) : null
            }
          />
        )}
      </ColumnsDirective>
      <Inject services={[Edit, Sort, Filter, Resize]} />
    </TreeGridComponent>
  );
};

export const MemoizedItemPropertiesGrid = memo(
  ItemPropertiesGrid,
  (prevState, nextState) =>
    isEqual(prevState.menuItem, nextState.menuItem) &&
    isEqual(prevState.formDetails, nextState.formDetails)
);
