import { memo } from 'react';
import { Box } from './Box';
import { EditSettingsModel } from '@syncfusion/ej2-react-treegrid';
import { SearchField } from './SearchField';
import {
  BusinessTypePropertyValues,
  BusinessTypes,
  BusinessTypesValue,
  Enums,
  ItemBusinessClass,
  ItemTypeProperties,
  PerspectiveClasses,
  RetentionClass,
} from '../types';
import { FilterDropdown } from './FilterDropdown';
import {
  isBoolean,
  isDate,
  isDateTime,
  isDouble,
  isEntity,
  isEnum,
  isInteger,
} from './item/utils';
import { DatePicker, DateTimePicker } from '@syncfusion/ej2-react-calendars';
import {
  getEnumValues,
  getPerspectiveClassValues,
} from './dialog/components/item/customTypeUtils';
import {
  AutoComplete,
  DropDownList,
  MultiSelect,
} from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBox } from '@syncfusion/ej2-react-inputs';
import { Field, useItemPropertiesPanel } from '../hooks/useItemPropertiesPanel';
import { MemoizedItemPropertiesGrid } from './grid/ItemPropertiesGrid';
import {
  EditBusinessTypeDialog,
  EditPerspectiveClassesDialog,
  EditRetentionClassesDialog,
} from './dialog';
import { Dialog } from './item';
import { FormDetailsObject } from '../hooks/useBusinessTypePanel';

export type ItemPropertiesPanelProps = {
  selectedTab?: string;
  itemTypeName?: string;
  businessTypeName?: string;
  propertyDetails: ItemTypeProperties | undefined;
  businessTypeTreeGridData: BusinessTypePropertyValues[];
  rowSelected?;
  formDetails?: FormDetailsObject;
  enums?: Enums;
  businessTypes?: BusinessTypes;
  retentionClasses?: RetentionClass[];
  perspectiveClasses?: PerspectiveClasses;
};

export const ItemPropertiesPanel = ({
  formDetails,
  itemTypeName,
  propertyDetails,
  businessTypeName,
  businessTypeTreeGridData,
  rowSelected,
  enums,
  businessTypes,
  retentionClasses,
  perspectiveClasses: _perspectiveClasses,
}: ItemPropertiesPanelProps) => {
  const item = propertyDetails?.value?.[0];
  const menuOptions = [businessTypeName, itemTypeName, 'All Properties'];
  const propLinksData = propertyDetails?.ResultPropLinks;

  const {
    gridRef,
    menuItem,
    dialogDetails,
    onChange,
    handleSearch,
    handleDialogSave,
    handleDialogClose,
    handleRowSelecting,
    handleRowSelection,
    handleActionComplete,
    handleRecordDoubleClick,
  } = useItemPropertiesPanel({
    item,
    formDetails,
    selectedTab: '',
    itemTypeName,
    propLinksData,
    businessTypeName,
    businessTypeTreeGridData,
    rowSelected,
  });

  let elem;
  let textField;
  let enumField;
  let doubleField;
  let booleanField;
  let integerField;
  let datePickerField;
  let businessTypeField;
  let selectedField = '';
  let dateTimePickerField;
  let retentionClassesField;
  let perspectiveClassField;
  let perspectiveClassesField;
  let selectedBusinessType = null;
  let selectedRetentionClasses = [];
  let selectedPerspectiveClass = null;
  let selectedPerspectiveClasses = [];

  const editOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    mode: 'Cell',
    newRowPosition: 'Below',
  };

  const editTemplate = {
    create: () => {
      elem = document.createElement('input');
      return elem;
    },
    read: (args) => {
      switch (selectedField) {
        case Field.PerspectiveClass:
          return selectedPerspectiveClass;
        case Field.Boolean:
          return booleanField?.value;
        case Field.BusinessType:
          return selectedBusinessType;
        case Field.BusinessClasses:
          return selectedPerspectiveClasses;
        case Field.Classifications:
          return selectedRetentionClasses;
        default:
          return args?.value?.value || args?.value;
      }
    },
    write: (args) => {
      const { rowData } = args;
      const { propDef } = rowData;

      selectedField = '';

      const isDateType = isDate(propDef);
      const isEnumType = isEnum(propDef);
      const isDoubleType = isDouble(propDef);
      const isBooleanType = isBoolean(propDef);
      const isIntegerType = isInteger(propDef);
      const isDateTimeType = isDateTime(propDef);
      const isBusinessType =
        isEntity(rowData?.propDef) && rowData?.propDef?.Name === 'BusinessType';
      const isBusinessClasses =
        isEntity(rowData?.propDef) &&
        rowData?.propDef?.Name === 'BusinessClasses';
      const isClassifications =
        isEntity(rowData?.propDef) &&
        rowData?.propDef?.Name === 'Classifications';

      const isPerspectiveClassType =
        isEntity(propDef) &&
        propDef?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
        propDef?.PropTypeDef?.Category?.InternalName === 'Inheritance';

      if (isDateType) {
        const value = args.rowData[args.column.field];

        datePickerField = new DatePicker({
          value,
          format: 'yyyy-MM-dd',
          showTodayButton: false,
          showClearButton: false,
          cssClass: 'custom-type-datepicker',
          allowEdit: false,
        });

        datePickerField.appendTo(elem);
      } else if (isDateTimeType) {
        const value = args.rowData[args.column.field];

        dateTimePickerField = new DateTimePicker({
          value,
          format: 'yyyy-MM-ddT00:00:00',
          showTodayButton: false,
          showClearButton: false,
          cssClass: 'custom-type-datepicker',
          allowEdit: false,
        });

        dateTimePickerField.appendTo(elem);
      } else if (isEnumType) {
        const value = args.rowData[args.column.field];

        const { Caption } = propDef;

        const enumValues = getEnumValues(enums, Caption);
        const sortOrder = 'Ascending';

        enumField = new DropDownList({
          value,
          fields: { text: 'Caption', value: 'name' },
          dataSource: enumValues,
          cssClass: 'custom-type-dropdown',
          sortOrder,
          popupHeight: '245px',
        });

        enumField.appendTo(elem);
      } else if (isIntegerType) {
        const value = args.rowData[args.column.field];

        integerField = new NumericTextBox({
          value,
          format: '####',
          cssClass: 'custom-type-numeric',
        });

        integerField.appendTo(elem);
      } else if (isDoubleType) {
        const value = args.rowData[args.column.field];

        doubleField = new NumericTextBox({
          value,
          format: '####.00',
          decimals: 2,
          cssClass: 'custom-type-numeric',
        });

        doubleField.appendTo(elem);
      } else if (isBooleanType) {
        const value = args.rowData[args.column.field];

        selectedField = Field.Boolean;

        booleanField = new DropDownList({
          value: value?.toString(),
          fields: { text: 'label', value: 'value' },
          dataSource: [
            { label: '', value: '' },
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ],
          cssClass: 'custom-type-dropdown',
        });

        booleanField.appendTo(elem);
      } else if (isPerspectiveClassType) {
        let value = args.rowData[args.column.field];

        selectedField = Field.PerspectiveClass;

        const propTypeDefId = propDef?.PropTypeDefId;
        const perspectiveClasses = getPerspectiveClassValues(
          _perspectiveClasses,
          propTypeDefId,
          ''
        );

        const perspectiveClassValue = perspectiveClasses?.find(
          (x) => x?._Display === value?._Display
        );

        if (value) selectedPerspectiveClass = perspectiveClassValue;
        else {
          value = {
            ID: null,
            TypeDefId: null,
            Active: true,
          };
        }

        perspectiveClassField = new DropDownList({
          value: perspectiveClassValue?.ID,
          dataSource: perspectiveClasses,
          fields: {
            text: '_Display',
            value: 'ID',
          },
          select: (res) => {
            perspectiveClasses.forEach((val: ItemBusinessClass) => {
              if (
                val.ID === res?.itemData?.ID ||
                (!val.ID && !res?.itemData?.ID)
              ) {
                selectedPerspectiveClass = val;
              }
            });
          },
          cssClass: 'custom-type-dropdown',
        });

        perspectiveClassField.appendTo(elem);
      } else if (isBusinessType) {
        let value = args.rowData[args.column.field];
        const sortOrder = 'Ascending';

        selectedField = Field.BusinessType;

        if (value) selectedBusinessType = value;
        else {
          value = {
            ID: null,
            TypeDefId: null,
            Active: true,
          };
        }

        businessTypeField = new DropDownList({
          value: value?.ID,
          fields: { text: '_Display', value: 'ID' },
          dataSource: businessTypes?.value,
          cssClass: 'custom-type-dropdown',
          sortOrder,
          popupHeight: '245px',
          select: (res) => {
            businessTypes?.value?.forEach((val) => {
              if (
                val.ID === res?.itemData?.ID ||
                (!val.ID && !res?.itemData?.ID)
              ) {
                selectedBusinessType = val;
              }
            });
          },
        });

        businessTypeField.appendTo(elem);
      } else if (isBusinessClasses) {
        let value = args.rowData[args.column.field];
        const sortOrder = 'Ascending';
        const selectedValues =
          (value?.value || value || [])?.map((x) => x.ID) ?? [];

        selectedField = Field.BusinessClasses;

        if (value?.value || value)
          selectedPerspectiveClasses = value?.value ?? value;
        else {
          value = {
            ID: null,
            TypeDefId: null,
            Active: true,
          };
        }

        perspectiveClassesField = new MultiSelect({
          value: selectedValues,
          fields: { text: '_Display', value: 'ID', groupBy: 'FullPath' },
          dataSource: _perspectiveClasses?.value,
          cssClass: 'custom-type-dropdown',
          sortOrder,
          mode: 'Box',
          popupHeight: '245px',
          showDropDownIcon: true,
          select: (res) => {
            _perspectiveClasses?.value?.forEach((val) => {
              if (
                val.ID === res?.itemData?.ID ||
                (!val.ID && !res?.itemData?.ID)
              ) {
                selectedPerspectiveClasses?.push(val);
              }
            });
          },
          removing: (res) => {
            selectedPerspectiveClasses = selectedPerspectiveClasses?.filter(
              (x) => x.ID !== res?.itemData?.ID
            );
          },
        });

        perspectiveClassesField.appendTo(elem);
      } else if (isClassifications) {
        let value = args.rowData[args.column.field];

        selectedField = Field.Classifications;

        const updatedRetentionClasses = retentionClasses?.map((x) => {
          return {
            ...x,
            DisplayNameWithFullPath: `${x.DisplayName} — ${x.FullPath}`,
          };
        });

        const sortOrder = 'Ascending';
        const selectedRetentions =
          (value?.value || value || [])?.map((x) => x.ID) ?? [];

        if (value?.value || value)
          selectedRetentionClasses = value?.value || value;
        else {
          value = {
            ID: null,
            TypeDefId: null,
            Active: true,
          };
        }

        retentionClassesField = new MultiSelect({
          value: selectedRetentions,
          fields: { text: 'DisplayNameWithFullPath', value: 'ID' },
          dataSource: updatedRetentionClasses,
          cssClass: 'custom-type-dropdown property-details-retention-classes',
          sortOrder,
          mode: 'Box',
          popupHeight: '245px',
          showDropDownIcon: true,
          select: (res) => {
            retentionClasses?.forEach((val) => {
              if (
                val.ID === res?.itemData?.ID ||
                (!val.ID && !res?.itemData?.ID)
              ) {
                selectedRetentionClasses?.push(val);
              }
            });
          },
          removing: (res) => {
            selectedRetentionClasses = selectedRetentionClasses?.filter(
              (x) => x.ID !== res?.itemData?.ID
            );
          },
        });

        retentionClassesField.appendTo(elem);
      } else {
        // TODO: update the component to TextBox, currently, there's an issue on inline editing.
        const value = args.rowData[args.column.field];

        textField = new AutoComplete({
          value,
          showClearButton: false,
          zIndex: -1000,
        });

        textField.appendTo(elem);
      }
    },
  };

  return (
    <>
      <Box
        padding='large'
        background='none'
        style={{
          paddingLeft: '1.75rem',
          paddingTop: '1.188rem',
        }}
      >
        <Box
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          background='none'
          style={{ marginBottom: '1.188rem', paddingTop: '0.625rem' }}
        >
          <SearchField
            width='17.188rem'
            height='3rem'
            onHandleSearch={handleSearch}
          />
          <FilterDropdown
            value={menuItem}
            onChange={onChange}
            menuOptions={menuOptions}
          />
        </Box>
        <Box background='none'>
          <MemoizedItemPropertiesGrid
            gridRef={gridRef}
            menuItem={menuItem}
            formDetails={formDetails}
            editOptions={editOptions}
            itemTypeName={itemTypeName}
            editTemplate={editTemplate}
            handleRowSelection={handleRowSelection}
            handleActionComplete={handleActionComplete}
            handleRowSelecting={handleRowSelecting}
            handleRecordDoubleClick={handleRecordDoubleClick}
          />
        </Box>
      </Box>

      {dialogDetails.dialog === Dialog.PERSPECTIVE && (
        <EditPerspectiveClassesDialog
          onSave={handleDialogSave}
          onClose={handleDialogClose}
          open={dialogDetails.open}
          title={dialogDetails.title}
          fieldLabel={dialogDetails.fieldLabel}
          propertyName={dialogDetails.propertyName}
          propertyValue={dialogDetails.propertyValue as ItemBusinessClass[]}
        />
      )}
      {dialogDetails.dialog === Dialog.RETENTION && (
        <EditRetentionClassesDialog
          onSave={handleDialogSave}
          onClose={handleDialogClose}
          open={dialogDetails.open}
          title={dialogDetails.title}
          fieldLabel={dialogDetails.fieldLabel}
          propertyName={dialogDetails.propertyName}
          propertyValue={dialogDetails.propertyValue as RetentionClass[]}
        />
      )}
      {dialogDetails.dialog === Dialog.BUSINESS_TYPE && (
        <EditBusinessTypeDialog
          onSave={handleDialogSave}
          onClose={handleDialogClose}
          open={dialogDetails.open}
          title={dialogDetails.title}
          fieldLabel={dialogDetails.fieldLabel}
          propertyName={dialogDetails.propertyName}
          propertyDetails={dialogDetails.propertyValue as BusinessTypesValue}
        />
      )}
    </>
  );
};

export const MemoizedItemPropertiesPanel = memo(ItemPropertiesPanel);
