import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { CloseButton } from '../button';
import { Box } from '../Box';
import { AppLogo } from '../AppLogo';
import { Divider } from '@mui/material';

export type AboutDialogProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  tenant?: any;
  version?: string;
  build?: string;
  commit?: string;
  content?: string;
};

export const AboutDialog: React.FC<AboutDialogProps> = ({
  open,
  onClose,
  title,
  tenant,
  version,
  build,
  commit,
  content,
}) => {
  title = title ?? 'About EncompaaS';
  tenant = tenant ?? '';
  version = version ?? '';
  build = build ?? '';
  commit = commit ?? '';
  content =
    content ??
    'Proudly brought to you by the EncompaaS implementation team across centers in Canberra, Sydney, Brisbane, Adelaide, Melbourne, Hobart, Burnie and Cambridge. \n\n Andy Saals, Doug Leung, Matt Abraham, Adam Pollard, Chris Hammond-Ross, Dan Theobald, Jaimie Tilbrook, Steve Taylor, Rob Tucker, David Barrie, Andrew Wood, Roger Hogg, Gordon Taylor, Cindy Liga, Melvin Villegas, Rob Hay, Erickson Chua Lee, Alex Overton, Anthony Drabsch, Conor Griffin, Jay Patterson, Paul Libauer\n\nWith contributions from XAM Consulting:\n\n Peter Gulliver, Tareq Sami Abu Zaid, Ardian Nuka, Samuel Wyndham, Carlo Dimaranan and Katreena Juntado\n';

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        background='light'
        direction='row'
        alignItems='center'
        height={6}
        gap='small'
        style={{
          padding: '0 1.5rem 0 0.75rem',
          justifyContent: 'space-between',
        }}
      >
        <AppLogo textColour='dark'></AppLogo>
        <CloseButton size='small' onClick={onClose}></CloseButton>
      </Box>
      <Divider
        sx={{
          margin: '0 2rem',
          border: '0.0625rem solid #D9DDE8',
        }}
      />

      <DialogTitle
        sx={{
          paddingBottom: '0rem',
          paddingTop: '0rem',
          backgroundColor: '#FFF',
          marginBottom: '0',
        }}
      >
        <Typography variant='h2'>{title}</Typography>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: '#FFF' }}>
        <Box direction='row' background='light' gap={3}>
          <Box background='light' width={5}>
            <Typography variant='h5' sx={{ lineHeight: '1.8rem' }}>
              Tenant
            </Typography>
            <Typography variant='h5' sx={{ lineHeight: '1.8rem' }}>
              Version
            </Typography>
            <Typography variant='h5' sx={{ lineHeight: '1.8rem' }}>
              Build
            </Typography>
            <Typography variant='h5' sx={{ lineHeight: '1.8rem' }}>
              Commit
            </Typography>
          </Box>
          <Box background='light'>
            <Typography variant='body2' sx={{ lineHeight: '1.8rem' }}>
              {tenant}
            </Typography>
            <Typography variant='body2' sx={{ lineHeight: '1.8rem' }}>
              {version}
            </Typography>
            <Typography variant='body2' sx={{ lineHeight: '1.8rem' }}>
              {build}
            </Typography>
            <Typography variant='body2' sx={{ lineHeight: '1.8rem' }}>
              {commit}
            </Typography>
          </Box>
        </Box>

        <Divider
          sx={{
            margin: '1.25rem 0 1.25rem 0',
            border: '0.0625rem solid #D9DDE8',
          }}
        />

        <Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>
          {content}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
