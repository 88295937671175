import { Button, Divider, styled, useTheme } from '@mui/material';
import { useDownloadFile } from '../hooks';
import { Box } from './Box';
import { MemoizedFileViewer } from './FileViewer';
import { MemoizedItemPropertiesPanel } from './ItemPropertiesPanel';
import { ItemSummaryPanel } from './ItemSummaryPanel';
import { ItemSummaryTitle } from './ItemSummaryTitle';
import { TabPanel } from './TabPanel';
import { Alert } from './alert';

import { useItemPanel } from '../hooks';
import { DisposalDetailsPanel } from './DisposalDetailsPanel';
import { DisposalRequest, ItemGridQuery } from '../types';
import { insertIf } from '../util/array-utils';
import { ColumnEdit20Regular } from '@fluentui/react-icons';
import { ItemsGrid } from './grid';
import { useCallback, useState } from 'react';
import { Dispatch, useMemo } from 'react';
import { memo } from 'react';
import { config } from '../config';

const StyledImage = styled('img')``;

export type ItemPanelProps = {
  id?: string;
  initialTab?: string;
  onSelectTab?: (tab: string) => void;
  adminMode?: boolean;
  disposalRequest?: DisposalRequest;
  setId?: Dispatch<any>;
};

export interface IHighlightsData {
  text: string;
  start: number;
  end: number;
}

/**
 * ItemPanel component to display details of an item
 * @param id - id of the Item
 * @param tab - selected tab ('summary' etc.,)
 * @event onSelectTab - select tab event
 * @returns ItemPanel JSX.Element
 */
export const ItemPanel = memo(
  ({
    id,
    initialTab,
    onSelectTab,
    adminMode,
    disposalRequest,
    setId,
  }: ItemPanelProps) => {
    // -----------------------------
    // Theme and utility hooks
    // -----------------------------
    const theme = useTheme();

    const {
      iconDetails,
      itemDetails,
      selectedTab,
      formDetails,
      handleSelectTab,
      propertyDetails,
      treeGridProperties,
      businessTypeProperties,
      businessTypeIconDetails,
      businessTypeTreeGridData,
      businessTypePropertyValues,
      itemCollectionCount,
      showColumnChooser,
      handleCheckbox,
      handleOpen,
      handleOpenURL,
      gridRef,
      selectedCheckbox,
      fullScreenTab,
      handleOpenItems,
      levels,
      hasPreviewableContent,
      setPreviewState,
      enums,
      businessTypes,
      retentionClasses,
      perspectiveClasses,
    } = useItemPanel({ id, initialTab, onSelectTab, disposalRequest, setId });

    const [selectedRowHighlight, setSelectedRowHighlight] = useState<
      Array<IHighlightsData>
    >([]);

    const [alertError, setAlertOpen] = useState(false);
    const [alertSuccess, setSuccessOpen] = useState(false);

    const stringToCopy = `${config.REACT_APP_ENC_LANDING_APP_URL}items/${itemDetails?.ID}`;
    const copyErrorMessage =
      'An error occurred while copying this link to clipboard';
    const copySuccessMessage = 'Link to this item has been copied to clipboard';

    const { downloadFile } = useDownloadFile();

    const rowSelected = useCallback((rowData) => {
      if (rowData?.data?.highlightData?.length > 0) {
        setSelectedRowHighlight(rowData.data.highlightData);
      }
    }, []);

    const handleCopyItemLink = () => {
      navigator.clipboard
        .writeText(stringToCopy)
        .then(() => {
          setSuccessOpen(true);
        })
        .catch((err) => {
          setAlertOpen(true);
        });
    };

    const MemoizedItemsGrid = useMemo(() => {
      return (
        <ItemsGrid
          isItemPanel
          ref={gridRef}
          adminMode={adminMode}
          onOpen={handleOpen}
          onOpenURL={handleOpenURL}
          onOpenItems={handleOpenItems}
          onSelect={handleCheckbox}
          disposalId={id!}
          disposalRequest={disposalRequest}
          itemGridType={ItemGridQuery.ChildWithStatus}
          selectedRecords={selectedCheckbox}
          persistanceId={`panelGrid${JSON.stringify(levels).length}-4`}
        />
      );
    }, [id, levels]);

    return (
      <Box background='none'>
        <ItemSummaryTitle
          isMoreButtonAvailable={hasPreviewableContent}
          icon={
            iconDetails ? (
              <StyledImage
                src={`${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`}
                alt={iconDetails?.AltText}
                style={{ height: '1.5rem' }}
              />
            ) : null
          }
          displayName={itemDetails?.DisplayName}
          itemType={itemDetails?.TypeDef?._Display}
          businessType={itemDetails?.BusinessType?._Display}
          onDownloadClick={() => {
            downloadFile({
              id,
              itemDetails,
            });
          }}
          onCopyLinkClick={() => {
            handleCopyItemLink();
          }}
        />
        <Box direction='row' background='none'>
          <Box background='none' width={!fullScreenTab ? '50%' : '100%'}>
            <TabPanel
              selected={selectedTab}
              onSelect={handleSelectTab}
              inset='3.5rem'
              tabs={[
                {
                  title: 'Summary',
                  value: 'summary',
                  children: (
                    <ItemSummaryPanel
                      item={itemDetails}
                      adminMode={adminMode}
                      disposalRequest={disposalRequest}
                      treeGridProperties={treeGridProperties}
                      businessTypeProperties={businessTypeProperties}
                      businessTypeIconDetails={businessTypeIconDetails}
                      businessTypePropertyValues={businessTypePropertyValues}
                      onOpen={handleOpen}
                    />
                  ),
                },
                {
                  title: 'Properties',
                  value: 'properties',
                  children: (
                    <MemoizedItemPropertiesPanel
                      selectedTab={selectedTab}
                      formDetails={formDetails}
                      propertyDetails={propertyDetails}
                      businessTypeTreeGridData={businessTypeTreeGridData}
                      rowSelected={rowSelected}
                      itemTypeName={
                        itemDetails?.TypeDef?._Display
                          ? `${itemDetails?.TypeDef?._Display} Properties`
                          : undefined
                      }
                      businessTypeName={
                        itemDetails?.BusinessType?._Display
                          ? `${itemDetails?.BusinessType?._Display} Properties`
                          : undefined
                      }
                      enums={enums}
                      businessTypes={businessTypes}
                      retentionClasses={retentionClasses}
                      perspectiveClasses={perspectiveClasses}
                    />
                  ),
                },
                {
                  title: 'Disposal Details',
                  value: 'disposalDetails',
                  children: <DisposalDetailsPanel itemId={itemDetails?.ID} />,
                },
                ...insertIf(
                  itemCollectionCount?.count &&
                    (itemDetails?.AttachedItems?.value.length > 0 ||
                      itemDetails?.TypeDef?._Display == 'Item Collection'),
                  {
                    title: `${itemCollectionCount?.count} ${
                      itemDetails.AttachedItems?.value?.length > 0
                        ? 'attachment'
                        : 'item'
                    }${itemCollectionCount?.count != 1 ? `s` : ``}`,
                    value: 'items',
                    children: (
                      <Box
                        padding='large'
                        background='none'
                        style={{
                          paddingTop: '0.6rem',
                          height: '100%',
                        }}
                      >
                        <Box
                          direction='row'
                          alignItems='center'
                          background='none'
                          height={5}
                        >
                          <Box
                            background='none'
                            gap={'small'}
                            direction='row'
                            justifyContent='end'
                          >
                            <Button
                              variant='text'
                              startIcon={<ColumnEdit20Regular />}
                              onClick={showColumnChooser}
                            >
                              Change Columns
                            </Button>
                          </Box>
                        </Box>
                        <Box
                          background='none'
                          className='item-panel-items-grid'
                          style={{
                            paddingTop: '0.6rem',
                            height: '100%',
                          }}
                        >
                          {MemoizedItemsGrid}
                        </Box>
                      </Box>
                    ),
                  }
                ),
              ]}
            />
          </Box>
          {!fullScreenTab && (
            <Box
              width='50%'
              height='calc(100% - 2rem)'
              alignItems='center'
              justifyContent='center'
              style={{
                backgroundColor: theme.palette.info[100],
                marginTop: '2rem',
              }}
            >
              {hasPreviewableContent && <Divider style={{ width: '100%' }} />}
              <MemoizedFileViewer
                id={id}
                itemDetails={itemDetails}
                rowData={selectedRowHighlight}
                setPreviewState={setPreviewState}
              />
            </Box>
          )}
        </Box>
        <Alert
          alertType='warning'
          message={copyErrorMessage}
          open={alertError}
          onClose={() => {
            setAlertOpen(false);
          }}
          autoHideDuration={10000}
        />
        <Alert
          alertType='success'
          message={copySuccessMessage}
          open={alertSuccess}
          onClose={() => {
            setSuccessOpen(false);
          }}
          autoHideDuration={10000}
        />
      </Box>
    );
  }
);

export const MemoizedItemPanel = memo(ItemPanel);
