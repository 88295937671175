import dayjs from "dayjs";
import { isDate } from "lodash";

//
export const formatDate = (isoDateString: string, isDateTime = true): string => {
  if (!isoDateString) return null;
  
  const dateObject = new Date(isoDateString);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const twoDigitDay = padSingleDigitWithZero(dateObject.getDate()).toLocaleString();
  const twoDigitMonth = padSingleDigitWithZero(dateObject.getMonth() + 1).toLocaleString();
  const fourDigitYear = dateObject.getFullYear();

  return !isDateTime ? `${twoDigitMonth}/${twoDigitDay}/${fourDigitYear}` : dateObject.toLocaleString(undefined, options).replace(',', '');
};

export const formatCustomTypeDate = (isoDateString: string, isDateTime = true): string => {
  const dateObject = new Date(isoDateString);

  const twoDigitDay = padSingleDigitWithZero(dateObject.getDate()).toLocaleString();
  const twoDigitMonth = padSingleDigitWithZero(dateObject.getMonth() + 1).toLocaleString();
  const fourDigitYear = dateObject.getFullYear();

  return isDateTime ? `${fourDigitYear}-${twoDigitMonth}-${twoDigitDay}T00:00:00` : `${fourDigitYear}-${twoDigitMonth}-${twoDigitDay}`;
};

export const padSingleDigitWithZero = (number: number): string => {
  return number < 10 ? `0${number}` : `${number}`;
};

export const getFormattedDateTime = (dateTimeStr: string) => {
  if (!dateTimeStr) return null;
  return dayjs(dateTimeStr).format("MM/DD/YYYY HH:mm");
}

export const formatDestructionDate = (isoDateString: string): string | null => {  
  const dateObject = new Date(isoDateString);

  const twoDigitDay = padSingleDigitWithZero(dateObject.getDate()).toLocaleString();
  const monthString = dateObject.toLocaleString('default', { month: 'short' }).toLocaleString();
  const fourDigitYear = dateObject.getFullYear();

  const twoDigitHour = padSingleDigitWithZero(dateObject.getHours()).toLocaleString();
  const twoDigitMinute = padSingleDigitWithZero(dateObject.getMinutes()).toLocaleString();

  return `${twoDigitDay} ${monthString} ${fourDigitYear}, ${twoDigitHour}:${twoDigitMinute}`;
};